import { faker } from '@faker-js/faker';
// utils
import { mockImgStory } from '../utils/mockImages';

// ----------------------------------------------------------------------

const STORY_TITLE = ['Star Wars', 'Castaway', 'The Sirens of Titan'];

// ----------------------------------------------------------------------

const stories = [...Array(3)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: mockImgStory(STORY_TITLE[index]),
  name: STORY_TITLE[index]
}));

export default stories;
