import '../pages/story-editor.css';
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Controls,
  Background,
  MiniMap,
  addEdge,
  updateEdge
} from 'react-flow-renderer';
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Box,
  TextField,
  Tabs,
  Tab
} from '@mui/material';
import { useCallback, useEffect, useState, useRef } from 'react';
import StoryEditorSidebar from './StoryEditorSidebar';

// import '../../public/static/css/story-editor.css';

let id = 0;
const getId = () => `dndnode_new_${(id += 1)}`;

function StoryEditorGraph(props) {
  const initialNodes = [];

  const { id, name } = props;
  const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nodeId, setNodeId] = useState(1);
  const { setViewport } = useReactFlow();
  const flowStyles = { height: 700 };
  const reactFlowInstance = useReactFlow();
  const reactFlowWrapper = useRef(null);

  const [flowValue, setFlowValue] = useState();

  const saveStory = () => {
    const flow = reactFlowInstance.toObject();
    const flowOutput = JSON.stringify(flow);
    setFlowValue(flowOutput);
  };

  async function getGraph(name) {
    const storyOrigin = `/static/mock-json/${name.toLowerCase().replace(' ', '-')}.json`;
    const response = await fetch(storyOrigin);
    const jsonOutput = await response.json();
    return jsonOutput;
  }

  const loadStory = () => {
    getGraph(name).then((flow) => updateGraph(flow));
  };

  const updateGraph = (flow) => {
    if (flow !== '') {
      const { x = 0, y = 0, zoom = 1 } = flow.viewport;
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setViewport({ x, y, zoom });
    }
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const onEdgeUpdate = (oldEdge, newConnection) =>
    setEdges((els) => updateEdge(oldEdge, newConnection, els));

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const storyType = event.dataTransfer.getData('application/reactflow');
      const type = 'default';

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${storyType} ${nodeId}` }
      };

      setNodeId(nodeId + 1);
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  return (
    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        style={flowStyles}
        onInit={loadStory}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onEdgeUpdate={onEdgeUpdate}
      >
        <Controls />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value="hola"
            aria-label="basic tabs example"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="Story Graph" />
            <Tab label="Structure" />
            <Tab label="Flows" />
            <Tab label="Timelines" />
            <Tab label="Characters" />
          </Tabs>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 100,
            top: 100,
            zIndex: 4,
            fontSize: 12,
            display: 'none'
          }}
        >
          <Button variant="contained" onClick={saveStory} type="button" color="secondary">
            Aprieta la wea
          </Button>
          <Button variant="contained" onClick={loadStory} type="button" color="secondary">
            Cargar
          </Button>
          <TextField id="outputText" variant="outlined" multiline label="" value={flowValue} />
        </Box>

        <MiniMap />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
}

export default function StoryEditor(props) {
  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <StoryEditorGraph id={props.id} name={props.name} />
        <StoryEditorSidebar />
      </ReactFlowProvider>
    </div>
  );
}
