import { Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { StoryList } from '../sections/@dashboard/stories';
import STORIES from '../_mocks_/stories';

export default function StoryCollection() {
  return (
    <Page title="My stories collection">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          My stories
        </Typography>

        <StoryList products={STORIES} />
      </Container>
    </Page>
  );
}
